import React from 'react'
import Layout from "../components/layout"
import Posts from "../components/post_list"

export default function BlogPage(props) {

    let {tag} = props.pageContext

    return (
    <Layout>
        <h1 className="text-center text-4xl font-semibold bg-gray-200">Articles for '{tag}'</h1>
        <div className="px-10 sm:px-20">
        <Posts postList={props.data}></Posts>
        </div>
    </Layout>
)}

export const tagsPageQuery = graphql`
query TagsPageQuery($tag: String!) {
    allMarkdownRemark(filter: {frontmatter: {tags: {eq: $tag}}}, sort: { order: DESC, fields: [frontmatter___date]}) {
        edges {
            node {
                id
                frontmatter {
                    path
                    title
                    date
                    author
                    featured_image {
                        alt
                        title
                        src {
                            childImageSharp {
                                fluid(maxWidth: 300) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                excerpt(pruneLength: 500)
            }
        }
    }
}`
